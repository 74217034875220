import './App.css';

function App() {
  return (
    <main className="background">
      <div className="top-line"></div>
      
      <div className="top-text">Raiskale.com</div>
      


    </main>
    
  );
}

export default App;
